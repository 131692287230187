import React from "react";

function Notifications() {
  return (
    <div>
      <h2>notifications page</h2>
    </div>
  );
}

export default Notifications;
