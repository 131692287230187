import React from "react";

function Messages() {
  return (
    <div>
      <h2>messages page</h2>
    </div>
  );
}

export default Messages;
